
class Utilities {
    static getCurrentRoundDate() {
        const currentTime = new Date();
        
        const utcTime = new Date(currentTime.getUTCFullYear(), currentTime.getUTCMonth(), currentTime.getUTCDate(),
            currentTime.getUTCHours(), currentTime.getUTCMinutes(), currentTime.getUTCSeconds(), 0);
        if (utcTime.getHours() >= 16) {
            return utcTime;        
        }
        else{
            utcTime.setDate(utcTime.getDate() - 1);
            return utcTime;
        }
    }

    static getWeekday(t, date)
    {
        let weekday = ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La", "Su"][new Date(date).getDay()]
        return weekday;
    }

    static pad(a, b){
        return(1e15 + a + '').slice(-b);
    }

    static getCountryPointsFromScores(scores, players) {
        let points = 0;
        scores.forEach(score => {
            if (score.goals !== undefined)        
            {
                score.goals.forEach(goal => {
                    // Do not count shootout goals and points made by goalies
                    if (goal.time !== "00:00")
                    {
                        if (players.find(p => p.playerId === goal.scorerId)) {
                            points++;
                        }
                        if (players.find(p => p.playerId === goal.assistOneId)) {
                            points++;
                        }
                        if (players.find(p => p.playerId === goal.assistTwoId)) {
                            points++;
                        }
                    }                    
                });
            }
        });

        return points;
    }

    static groupBy = (xs, key) =>{
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };

    static getTimeString(seconds)
    {
        if (seconds === undefined)
        {
            return "00:00";
        }
        
        return `${this.pad((seconds / 60).toFixed(0), 2)}:${this.pad((seconds % 60).toFixed(0), 2)}`        
    }
}

export default Utilities;